import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebarShow: true,
    sidebarUnfoldable: false,
  },
  reducers: {
    changeUIState: (state, { payload }) => {
      for (const key in payload) {
        state[key] = payload[key]
      }
    },
  },
})

export const { changeUIState } = uiSlice.actions

export default uiSlice.reducer
