import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { post } from 'src/helpers/fetch'

import { POST_LOGIN, POST_VERIFY_OTP } from '../../config/backendUrl'

export const login = createAsyncThunk('register', async (data) => {
  return await post(POST_LOGIN, data)
})

export const verifyOtp = createAsyncThunk('verify-otp', async (data) => {
  return await post(POST_VERIFY_OTP, data)
})

export const authSlice = createSlice({
  name: 'cookie',
  initialState: {
    token: null,
    user: null,
    totpUri: null,
    loginError: null,
    qrCodeError: null,
  },
  reducers: {
    setToken: (state, { payload }) => {
      const { token } = payload
      state.token = token
    },
    setUser: (state, { payload }) => {
      const { user } = payload
      state.user = user
    },
    beginLogin: (state) => {
      state.loginError = null
      state.qrCodeError = null
    },
    logout: (state) => {
      state.loginError = null
      state.qrCodeError = null
      state.user = null
      state.token = null
      state.totpUri = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      if (!payload.error.status) {
        state.token = payload.payload.token
        state.user = payload.payload.user
        state.totpUri = payload.payload.totpUri
      } else {
        state.loginError = payload.error.message
      }
    })
    builder.addCase(verifyOtp.fulfilled, (state, { payload }) => {
      if (!payload.error.status) {
        state.user = payload.payload.user
        state.qrCodeError = null
      } else {
        state.qrCodeError = payload.error.message
      }
    })
  },
})

export const { setToken, setUser, beginLogin, logout } = authSlice.actions

export default authSlice.reducer
